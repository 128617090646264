import React from 'react'
import { connect, useSelector } from 'react-redux'
import { shallow } from 'zustand/shallow'

import { Alert, Box, Grid, Paper, Tab, Tabs, Typography } from '@mui/material'

import AuthActions from '../redux/authRedux'
import { DASH } from '../utils/constants'
import { UsePerformanceStore } from '../stores/UsePerformanceStore'
import { getUser } from '../selectors'

import InfoBadge from '../components/widgets/InfoBadge'
import Page from '../components/Layout/Page'
import Performance from '../components/widgets/Performance'
import TabPanel from '../components/widgets/TabPanel'
import Transactions from '../components/widgets/Transactions'
import { QualityBonusBooster, QuantityBonusBooster } from '../components/widgets/BonusBooster'


type Props = {
  loadUserBalanceAction: () => void
}

function DashboardPage({ loadUserBalanceAction }: Props) {
  const [tab, setTab] = React.useState<number>(0)
  const user = useSelector(getUser)
  const { statistic, today, loadPerformance, loadStatistic } = UsePerformanceStore((state) => ({
    ...state,
    today: state.get_today(),
  }), shallow)

  React.useEffect(() => {
    loadUserBalanceAction()
    loadPerformance(user.id)
    loadStatistic(user.id)
  }, [user.id, loadUserBalanceAction, loadPerformance, loadStatistic])

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => setTab(newValue)

  return (
    <Page
      className="dashboardPage"
      title="Dashboard Page"
    >
      <Paper elevation={3} style={{ marginBottom: 24, padding: 16, justifyContent: "center" }}>
        <Grid container spacing={3}>
          {user.is_ban && (
            <Grid item xs={12}>
              <Alert severity="error">
                Your account has been banned for bad quality of voting.
                Please contact manager for unban.
              </Alert>
            </Grid>
          )}
          <Grid item xs={12} sm={6}>
            <Typography component="h2" variant="h6" color="primary">
              Total:
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography component="h2" variant="h6" align={"right"}>
              {`Hello ${user.first_name} ${user.last_name}! ID: #${user.id}`}
            </Typography>
          </Grid>

          <Grid item xs={6} sm={2}>
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <InfoBadge
                title="Processed"
                value={`${statistic.finished}`}
              />
            </Paper>
          </Grid>
          <Grid item xs={6} sm={2}>
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <InfoBadge
                title="Total"
                value={`${statistic.total}`}
              />
            </Paper>
          </Grid>
          <Grid item xs={6} sm={2}>
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <InfoBadge
                title="Resolution"
                value={statistic.resolution_avg ? `${statistic.resolution_avg.toFixed(2)} %` : DASH}
              />
            </Paper>
          </Grid>
          <Grid item xs={6} sm={2}>
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <InfoBadge
                title="Error Rate"
                value={statistic.reference_error_avg ? `${statistic.reference_error_avg.toFixed(2)} %` : DASH}
              />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <InfoBadge
                title="Balance"
                value={'$ ' + (user.balance / 100).toFixed(2)}
              />
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <Typography component="h2" variant="h6" color="primary">Today:</Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <QualityBonusBooster
                value={today?.resolution_avg}
              />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <QuantityBonusBooster value={today?.total} />
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={tab} onChange={handleChangeTab}>
                <Tab label="Transactions" id="simple-tab-0" aria-controls="simple-tabpanel-0" />
                <Tab label="Performance" id="simple-tab-1" aria-controls="simple-tabpanel-1" />
              </Tabs>
            </Box>
            <TabPanel value={tab} index={0} xs={{ p: 3 }}>
              <Transactions />
            </TabPanel>
            <TabPanel value={tab} index={1} xs={{ p: 3 }}>
              <Performance />
            </TabPanel>
          </Grid>
        </Grid>
      </Paper>
    </Page>
  )
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    loadUserBalanceAction: () => dispatch(AuthActions.loadUserBalance()),
  }
}

export default connect(null, mapDispatchToProps)(DashboardPage);
