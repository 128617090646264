import React from 'react';
import { SxProps } from '@mui/system'
import Box from '@mui/material/Box'

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  xs?: SxProps
}

export default function TabPanel(props: TabPanelProps) {
  const { children, value, index, xs, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={xs}>
          {children}
        </Box>
      )}
    </div>
  );
}
