import React from 'react'
import { connect } from 'react-redux'
import AuthActions from '../../redux/authRedux'
import { IconButton, Typography, Menu, MenuItem } from '@mui/material'
import { AccountCircle } from '@mui/icons-material'
import { useHistory } from 'react-router-dom'

interface iProps {
  remember_user_id: number
  user: any

  onMenuClick?: () => void
  reLogin: () => void
  logout: () => void
}

function ProfileDropDown(props: iProps) {
  const { remember_user_id, user } = props
  const history = useHistory()

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const isMyAccount = () => remember_user_id && remember_user_id !== user.id

  const handleUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }

  const handleUserMenuClose = () => {
    setAnchorEl(null)
  }

  const logout = () => {
    handleUserMenuClose()
    props.logout()
  }

  const navigateTo = (path: string) => {
    handleUserMenuClose()
    history.push(path)
  }

  return (
    <>
      <IconButton
        aria-label="account of current user"
        aria-controls="menu-profile"
        aria-haspopup="true"
        onClick={handleUserMenu}
        color={isMyAccount() ? 'error' : 'inherit'}
      >
        <AccountCircle />
      </IconButton>
      <Menu
        id="menu-profile"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleUserMenuClose}
      >
        <MenuItem key={0} onClick={() => navigateTo('/')} divider>Dashboard</MenuItem>
        <MenuItem
          key={10}
          onClick={() => navigateTo('/voting')}
          disabled={!user.permissions.allow_matching}
          title={user.permissions.allow_matching ? '' : 'Not available'}
        >Voting</MenuItem>
        {(!user?.is_ban && user.permissions.allow_dispute) && (
          <MenuItem key={20} onClick={() => navigateTo('/manager/disputes/')}>Arbitrage dispute</MenuItem>
        )}
        {(!user?.is_ban && user.permissions.allow_manage) && ([
          <MenuItem key={21} onClick={() => navigateTo('/manager/disputes-list/')}>All disputes</MenuItem>,
          <MenuItem key={22} onClick={() => navigateTo('/manager/reference-evaluation-requests/')}>Reference Evaluation Requests</MenuItem>,
          <MenuItem key={23} onClick={() => navigateTo('/manager/vote-history')}>Vote History</MenuItem>,
        ])}
        {(!user?.is_ban && user.permissions.allow_users) && ([
          <MenuItem key={30} divider />,
          <MenuItem key={31} onClick={() => navigateTo('/manager/users')}>Manage users</MenuItem>,
          <MenuItem key={32} onClick={() => navigateTo('/manager/matching-performance')}>Matching Performance</MenuItem>,
          <MenuItem key={33} onClick={() => navigateTo('/manager/performance')}>Transaction Performance</MenuItem>,
        ])}
        {/*(!user?.is_ban && user.permissions.allow_manage) && ([
          <MenuItem key={40} divider />,
          <MenuItem key={42} onClick={() => navigateTo('/settings/buckets/')}>Deduplication buckets</MenuItem>,
        ])*/}
        {(!user?.is_ban && user.permissions.allow_blacklist_images) && ([
          <MenuItem key={43} divider />,
          <MenuItem key={44} onClick={() => navigateTo('/blacklist-image/')}>Start Image Blacklisting</MenuItem>,
        ])}
        <MenuItem key={50} divider />
        {isMyAccount() ? (
          <MenuItem key={51} onClick={props.reLogin}>
            <Typography color="error">Back in my</Typography>
          </MenuItem>
        ) : null}
        <MenuItem key={99} onClick={logout}>Logout</MenuItem>
      </Menu>
    </>
  )
}

const mapStateToProps = (state: any) => {
  return {
    remember_user_id: state.auth.remember_user_id,
    user: state.auth.user,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    reLogin: () => dispatch(AuthActions.reLogin()),
    logout: () => dispatch(AuthActions.logout()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileDropDown)
