import React from 'react'
import { IconButton, Tooltip, Typography } from '@mui/material'
import { Help as IconHelp } from '@mui/icons-material'

interface iProps {
  title: string
  value: string
  tooltip?: string
}

export default function InfoBadge(props: iProps) {
  return (
    <>
      <Typography
        component="h2"
        variant="h6"
        color="primary"
        gutterBottom
        sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", height: 40 }}
      >
        {props.title}
        {props.tooltip && (
          <Tooltip title={props.tooltip}>
            <IconButton><IconHelp /></IconButton>
          </Tooltip>
        )}
      </Typography>
      <Typography component="p" variant="h4">
        {props.value}
      </Typography>
    </>
  );
}
