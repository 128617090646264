import React from 'react'
import { shallow } from 'zustand/shallow'
import { useHistory } from 'react-router-dom'

import { Alert, AlertTitle, Button, Stack } from '@mui/material'
import { DataGrid, GridColDef, GridFilterModel, GridRenderCellParams, GridSortModel } from '@mui/x-data-grid'

import Page from '../../components/Layout/Page'
import { STATUS, UseEvaluationRequestStore } from '../../stores/UseEvaluationRequestStore'
import { datetimeFormatter } from '../../utils/formatters'

const FILTER: GridFilterModel = {
  items: [
    {
      columnField: 'status',
      operatorValue: '=',
      value: STATUS.InProcess,
    },
    {
      columnField: 'is_reference',
      operatorValue: '=',
      value: 1,
    },
  ],
}
const SORT: GridSortModel = [
  {
    field: 'updated_at',
    sort: 'desc',
  }
]

function ReferenceEvaluationRequestsPage() {
  const history = useHistory()
  const store = UseEvaluationRequestStore((state) => state, shallow)

  React.useEffect(() => {
    store.load(FILTER, SORT)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleOpenDispiute = (row: any) => {
    store.saveInViewed(row.id)
    history.push(`/disputes/${row.last_vote}/`)
  }

  if (!store.list.length) {
    return <Stack sx={{ width: '100%' }} spacing={2}>
      <Alert severity="error">
        <AlertTitle>There are any reference EvaluationRequest</AlertTitle>
        Ask developers...
      </Alert>
    </Stack>
  }

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      type: 'number',
      minWidth: 90,
    },
    {
      field: 'bucket_id',
      headerName: 'Bucket ID',
      type: 'number',
      minWidth: 90,
    },
    {
      field: 'candidates',
      headerName: 'Candidates count',
      minWidth: 150,
      renderCell: (params: GridRenderCellParams) => params.value?.length,
    },
    {
      field: 'confirmed_duplicates',
      headerName: 'Confirmed count',
      minWidth: 150,
      renderCell: (params: GridRenderCellParams) => params.value?.length,
    },
    {
      field: 'votes_count',
      headerName: 'Count of votes',
      type: 'number',
      minWidth: 150,
    },
    {
      field: 'created_at',
      headerName: 'Created at',
      type: 'dateTime',
      minWidth: 250,
      renderCell: (params) => datetimeFormatter(params.value),
    },
    {
      field: 'updated_at',
      headerName: 'Updated at',
      type: 'dateTime',
      minWidth: 250,
      renderCell: (params) => datetimeFormatter(params.value),
    },
    {
      field: 'last_vote',
      headerName: 'Actions',
      flex: 1,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => (
        <Button
          component="button"
          color={store.isViewed(params.row.id) ? 'success' : 'warning'}
          onClick={() => handleOpenDispiute(params.row)}
        >
          {store.isViewed(params.row.id) ? 'Viewed' : 'Not viewed'}
        </Button>
      ),
    },
  ]

  return (
    <Page
      className="referenceEvaluationRequestPage"
      title="Reference Evaluation Requests"
    >
      <DataGrid
        rows={store.list}
        columns={columns}
        autoHeight={true}
        loading={store.loading}
        disableColumnFilter={true}
        getRowClassName={(params) => params.row.is_new && 'bg-success'}
      />
    </Page>
  )
}

export default ReferenceEvaluationRequestsPage
