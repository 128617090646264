import { takeEvery, all, takeLatest, takeLeading } from 'redux-saga/effects'

/* ------------- Types ------------- */
import { AuthTypes } from '../redux/authRedux'
import { DisputeTypes } from '../redux/disputeRedux'
import { TransactionsTypes } from '../redux/transactionsRedux'
import { VotingTypes } from '../redux/votingRedux'
import { UsersTypes } from '../redux/usersRedux'
import { BucketsTypes } from '../redux/bucketsRedux'

/* ------------- Sagas ------------- */
import {
  login,
  reLogin,
  loadUserBalance,
  signup,
  confirmEmail,
  logout,
  requestPasswordReset,
  passwordReset,
} from './authSagas'

import {
  select,
  get_statistic,
} from './disputeSagas'

import {
  create,
  list,
  update,
} from './restSagas'

import {
  goNext,
  goBack,
  sendCandidateDuplicates,
  finish,
  skip,
  loadById,
} from './votingSagas'

import {
  loadUsersList,
  patchUser,
  sendConfirmEmail,
} from './usersSagas'

import {
  loadBucketsList,
  patchBuckets,
} from './bucketsSagas'

/* ------------- Connect Types To Sagas ------------- */

export default function* root() {
  yield all([
    // Auth
    takeLatest(AuthTypes.LOGIN, login),
    takeLatest(AuthTypes.RE_LOGIN, reLogin),
    takeLatest(AuthTypes.LOAD_USER_BALANCE, loadUserBalance),
    takeLatest(AuthTypes.SIGNUP, signup),
    takeLatest(AuthTypes.CONFIRM_EMAIL, confirmEmail),
    takeEvery(AuthTypes.LOGOUT, logout),
    takeLatest(AuthTypes.REQUEST_PASSWORD_RESET, requestPasswordReset),
    takeLatest(AuthTypes.PASSWORD_RESET, passwordReset),
    // Disputes
    takeLeading(DisputeTypes.SELECT, select),
    takeEvery(DisputeTypes.CREATE_DISPUTE, create),
    takeEvery(DisputeTypes.UPDATE_DISPUTE, update),
    takeLatest(DisputeTypes.GET_STATISTIC, get_statistic),
    takeLatest(DisputeTypes.LIST_DISPUTE, list),
    // transactions
    takeEvery(TransactionsTypes.CREATE_TRANSACTIONS, create),
    takeLatest(TransactionsTypes.LOAD_TRANSACTIONS, list),
    // Voting (tasks)
    takeLeading(VotingTypes.GO_NEXT, goNext),
    takeLeading(VotingTypes.GO_BACK, goBack),
    takeLatest(VotingTypes.SEND_CANDIDATE_DUPLICATES, sendCandidateDuplicates),
    takeEvery(VotingTypes.FINISH, finish),
    takeLeading(VotingTypes.SKIP, skip),
    takeLatest(VotingTypes.LOAD_BY_ID, loadById),
    // Users
    takeLatest(UsersTypes.LOAD_USERS_LIST, loadUsersList),
    takeEvery(UsersTypes.PATCH_USER, patchUser),
    takeLatest(UsersTypes.SEND_CONFIRM_EMAIL, sendConfirmEmail),
    // Buckets
    takeLatest(BucketsTypes.LOAD_BUCKETS_LIST, loadBucketsList),
    takeEvery(BucketsTypes.PATCH_BUCKETS, patchBuckets),
  ])
}
