import React, {RefObject} from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

import AuthActions from '../redux/authRedux'
import {
  Avatar, Box, Container, FormControl, FormHelperText, Grid, IconButton, InputAdornment, Link, TextField, Typography,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { HowToRegOutlined, Visibility, VisibilityOff } from '@mui/icons-material'

type Props = {
  fetching: boolean,
  errors: any,
  signup: (email: string, first_name: string, last_name: string, password: string, password2: string, g_recaptcha_token: any, history: any) => void,
}

interface iState {
  first_name: string
  last_name: string
  email: string
  password: string
  password2: string
  g_recaptcha_token: any
  showPassword: boolean
}

function SignupPage(props: Props) {
  const history = useHistory()
  const { fetching, errors } = props
  const [values, setValues] = React.useState<iState>({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    password2: '',
    g_recaptcha_token: null,
    showPassword: false,
  })
  const recaptchaRef: RefObject<any> = React.createRef()
  const recaptchaKey: string = process.env.REACT_APP_RE_CAPTCHA_KEY || ''

  const handleChange = (prop: keyof iState) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleBooleanChange = (prop: keyof iState) => () => {
    setValues({ ...values, [prop]: !values[prop] });
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    props.signup(values.email, values.first_name, values.last_name, values.password, values.password2, values.g_recaptcha_token, history)
    recaptchaRef.current.reset()
  }

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <HowToRegOutlined />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit}
          noValidate
          sx={{ m: 1 }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                id="name"
                value={values.first_name}
                onChange={handleChange('first_name')}
                required
                fullWidth
                margin="normal"
                autoComplete="name"
                label="First name"
                error={Boolean(errors?.first_name)}
                helperText={errors?.first_name && errors.first_name}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="last_name"
                value={values.last_name}
                onChange={handleChange('last_name')}
                required
                fullWidth
                margin="normal"
                autoComplete="family-name"
                label="Last name"
                error={Boolean(errors?.last_name)}
                helperText={errors?.last_name}
              />
            </Grid>
          </Grid>
          <TextField
            id="email"
            value={values.email}
            onChange={handleChange('email')}
            required
            fullWidth
            margin="normal"
            autoComplete="email"
            label="Email Address"
            error={Boolean(errors.email)}
            helperText={errors?.email}
          />
          <TextField
            id="password"
            type={values.showPassword ? 'text' : 'password'}
            value={values.password}
            onChange={handleChange('password')}
            required
            fullWidth
            margin="normal"
            label="Password"
            InputProps={{
              endAdornment:
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleBooleanChange('showPassword')}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
            }}
            error={Boolean(errors?.password)}
            helperText={errors?.password}
          />
          <TextField
            id="password2"
            type={values.showPassword ? 'text' : 'password'}
            value={values.password2}
            onChange={handleChange('password2')}
            required
            fullWidth
            margin="normal"
            label="Confirm Password"
            InputProps={{
              endAdornment:
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleBooleanChange('showPassword')}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
            }}
            error={Boolean(errors?.password2)}
            helperText={errors?.password2}
          />

          <FormControl>
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={recaptchaKey}
              onChange={token => {
                setValues({ ...values, 'g_recaptcha_token': token })
              }}
            />
            <FormHelperText
              error={Boolean(errors?.g_recaptcha_token)}
            >
              {errors?.g_recaptcha_token}
            </FormHelperText>
          </FormControl>
          <LoadingButton
            loading={fetching}
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign Up
          </LoadingButton>
          <Grid container>
            <Grid item xs>
              <Link href="/password-reset/" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href="/login" variant="body2">
                Have an account? Sign In
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  )
}

const mapStateToProps = (state: any) => {
  return {
    fetching: state.auth.fetching,
    errors: state.auth.errors,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    signup: (email: string, first_name: string, last_name: string, password: string, password2: string, g_recaptcha_token: any, history: any) => dispatch(AuthActions.signup(email, first_name, last_name, password, password2, g_recaptcha_token, history)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignupPage);
