import React from 'react'
import { connect } from 'react-redux'
import DisputeActions, { iDispute, STATUS_LIST } from '../../redux/disputeRedux'
import {
  DataGrid,
  getGridDateOperators,
  getGridNumericOperators,
  GridColDef,
  GridFilterModel,
  GridRenderCellParams,
  GridSortModel
} from '@mui/x-data-grid'
import Page from '../../components/Layout/Page'
import UserAutocomplete from '../../components/inputs/UserAutocomplete'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { Box, Stack, TextField } from '@mui/material'
import {
  Check as IconCheck,
  Close as IconCross,
  QuestionMark as IconQuestion,
} from '@mui/icons-material'
import { DASH, PAGE_SIZES } from '../../utils/constants'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { dateToISO } from '../../utils/formatters'
import { iSimpleUser } from '../../redux/authRedux'
import { validateDateRange } from '../../utils/functions'

interface iProps {
  count: number
  disputes: iDispute[]
  fetching: boolean

  loadList: (page: number, pageSize: number, sort: any, filter: any) => void
}

interface iState {
  page: number
  pageSize: number
  sort: GridSortModel
  filter: GridFilterModel | false
}

const StatusIcon = (params: GridRenderCellParams) => {
  // @ts-ignore
  const status = STATUS_LIST[params.row.status] || 'UNKNOWN'
  const systemIcon = params.row.is_matched_by_system === true
    ? <IconCheck fontSize="small" titleAccess="Matched by system" sx={{display: 'flex'}} />
    : params.row.is_matched_by_system === false
    ? <IconCross fontSize="small" titleAccess="Not matched by system" sx={{display: 'flex'}} />
    : <IconQuestion fontSize="small" titleAccess="Historized, not actual" sx={{display: 'flex'}} />
  const userIcon = params.row.is_matched_by_user === true
    ? <IconCheck fontSize="small" titleAccess="Matched by user" sx={{display: 'flex'}} />
    : params.row.is_matched_by_user === false
    ? <IconCross fontSize="small" titleAccess="Not matched by user" sx={{display: 'flex'}} />
    : <IconQuestion fontSize="small" titleAccess="Historized, not actual" sx={{display: 'flex'}} />
  return <>
    {status} | {systemIcon} | {userIcon}
  </>
}

const columns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'ID',
    type: 'number',
    width: 90,
    filterOperators: getGridNumericOperators().filter(
      (operator) => ['=', '>', '<'].includes(operator.value),
    ),
  },
  {
    field: 'evaluation_request_id',
    headerName: 'Evaluation ID',
    width: 150,
    filterable: false,
  },
  {
    field: 'vote',
    headerName: 'Vote',
    width: 150,
    renderCell: (params) => <a href={`/disputes/${params.value}/`}>{params.value}</a>,
    filterOperators: getGridNumericOperators().filter(
      (operator) => ['='].includes(operator.value),
    ),
  },
  {
    field: 'user',
    headerName: 'User',
    width: 200,
    type: 'string',
    filterable: false,
    valueGetter: (params) => `${params.value.first_name} ${params.value.last_name}`
  },
  {
    field: 'created_at',
    headerName: 'Created At',
    type: 'date',
    width: 250,
    filterOperators: getGridDateOperators().filter(
      (operator) => ['is', 'after', 'before'].includes(operator.value),
    ),
    valueFormatter: (params) => {
      if (params.value) {
        let date = new Date(Date.parse(params.value as string))
        return date.toLocaleDateString() + ' ' + date.toLocaleTimeString()
      }
    },
  },
  {
    field: 'status',
    headerName: 'Status',
    type: 'singleSelect',
    width: 150,
    valueOptions: Object.keys(STATUS_LIST),
    valueFormatter: ({ value }) => {
      // @ts-ignore
      return STATUS_LIST[value]
    },
    renderCell: StatusIcon,
  },
  {
    field: 'manager',
    headerName: 'Manager',
    width: 200,
    type: 'string',
    filterable: false,
    valueGetter: (params) => {
      if (params.value) {
        return `${params.value.first_name} ${params.value.last_name}`
      }
      return DASH
    },
  },
  {
    field: 'updated_at',
    headerName: 'Updated At',
    type: 'date',
    width: 250,
    filterOperators: getGridDateOperators().filter(
      (operator) => ['is', 'after', 'before'].includes(operator.value),
    ),
    // @todo replace into formatters.tsx
    valueFormatter: (params) => {
      if (params.value) {
        let date = new Date(Date.parse(params.value as string))
        return date.toLocaleDateString() + ' ' + date.toLocaleTimeString()
      }
    },
  },
]

function ManageDisputeListPage(props: iProps) {
  const { count, disputes, fetching, loadList } = props
  const [startDate, setStartDate] = React.useState<Date | null | undefined>(null)
  const [endDate, setEndDate] = React.useState<Date | null | undefined>(null)
  const [managers, setManagers] = React.useState<iSimpleUser[]>([])
  const [state, setState] = React.useState<iState>({
    page: 0,
    pageSize: 20,
    sort: [{ field: 'updated_at', sort: 'desc' }],
    filter: false,
  })

  const dateError: string = startDate && endDate ? validateDateRange(startDate, endDate) : '';

  React.useEffect(() => {
    const filter: GridFilterModel = {
      items: [],
      ...(state.filter && state.filter),
    };

    try {
      if (startDate && !Boolean(dateError)) {
        filter.items.push({
          columnField: 'updated_at',
          operatorValue: '>',
          value: dateToISO(startDate),
        });
      }

      if (endDate && !Boolean(dateError)) {
        filter?.items?.push({
          columnField: 'updated_at',
          operatorValue: '<',
          value: dateToISO(endDate),
        });
      }

      if (managers?.length) {
        filter?.items?.push({
          columnField: 'manager_id',
          operatorValue: 'isAnyOf',
          value: managers?.map(({ id }) => id),
        });
      }
    } catch (error) {
      console.info('dateNotValid', error);
    }

    loadList(state.page, state.pageSize, state.sort, filter)
  }, [loadList, state.page, state.pageSize, state.sort, state.filter, startDate, endDate, managers, dateError])

  const handleSortChange = (newSort: GridSortModel) => {
    setState({ ...state, sort: newSort })
  }

  const onFilterChange = (newFilter: GridFilterModel) => {
    setState({ ...state, filter: newFilter })
  }

  return (
    <Page
      className="disputesListPage"
      title="Disputes list"
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        spacing={2}
        sx={{ my: 2 }}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Box display="flex" flexDirection="row">
            <DatePicker
              inputFormat="E dd MMM yyyy"
              label="Start date"
              value={startDate}
              onChange={setStartDate}
              renderInput={(params) => (
                <TextField {...params} error={Boolean(dateError)} helperText={dateError} />
              )}
            />
            <Box display="flex" sx={{ m: 2 }}> to </Box>
            <DatePicker
              inputFormat="E dd MMM yyyy"
              label="End date"
              value={endDate}
              onChange={setEndDate}
              renderInput={(params) => (
                <TextField {...params} error={Boolean(dateError)} />
              )}
            />
          </Box>
        </LocalizationProvider>
        <UserAutocomplete
          value={managers}
          onChange={setManagers}
          inputLabel="Filter by manager"
        />
      </Stack>
      <DataGrid
        rows={disputes}
        columns={columns}
        autoHeight={true}
        // pagination
        rowsPerPageOptions={PAGE_SIZES}
        rowCount={count}
        page={state.page}
        pageSize={state.pageSize}
        paginationMode="server"
        onPageChange={(newPage) => {
          setState({ ...state, page: newPage })
        }}
        onPageSizeChange={(newPageSize) => {
          setState({ ...state, page: 0 })
          setState({ ...state, pageSize: newPageSize })
        }}
        // sorting
        sortingMode="server"
        sortModel={state.sort}
        onSortModelChange={handleSortChange}
        // filtering
        filterMode="server"
        onFilterModelChange={onFilterChange}
        loading={fetching}
      />
    </Page>
  )
}

const mapStateToProps = (state: any) => {
  return {
    disputes: state.dispute.list,
    count: state.dispute.count,
    fetching: state.dispute.fetching,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    loadList: (page: number, pageSize: number, sort: any, filter: any) => dispatch(DisputeActions.listDispute(page, pageSize, sort, filter)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageDisputeListPage)
