import React from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Alert, AlertTitle, Avatar, Box, Container, Grid, Link, TextField, Typography } from '@mui/material'
import { PasswordRounded as PasswordRoundedIcon } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'

import AuthActions from '../redux/authRedux'

type Props = {
  fetching: boolean,
  errors: any,
  message: string,

  requestPasswordReset: (email: string) => void,
  passwordReset: (id: any, token: any, password: string) => void,
}

type Params = {
  id: string;
  token: string;
}

interface iState {
  email: string,
  password: string,
}

function PasswordResetPage(props: Props) {
  const { fetching, errors, message } = props
  const [values, setValues] = React.useState<iState>({
    email: '',
    password: '',
  })

  const { id, token } = useParams<Params>()

  const handleChange = (prop: keyof iState) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [prop]: event.target.value });
  }

  const handleSubmitRequest = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    props.requestPasswordReset(values.email)
  }

  const handleSubmitPassword = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    props.passwordReset(id, token, values.password)
  }

  return (
    <Container maxWidth="sm">
      <Box sx={{
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <PasswordRoundedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Password reset
        </Typography>
        {(id && token) ? (
          <Box
            component="form"
            onSubmit={handleSubmitPassword}
            noValidate
            sx={{ m: 1 }}
          >
            <Box sx={{ 'display': errors.non_field_errors ? 'none' : 'block' }}>
              <TextField
                id="password"
                value={values.password}
                onChange={handleChange('password')}
                required
                fullWidth
                margin="normal"
                autoComplete=""
                autoFocus
                label="New password"
                error={Boolean(errors.password)}
                helperText={errors.password}
              />
              <LoadingButton
                loading={fetching}
                disabled={Boolean(message)}
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Update password
              </LoadingButton>
              <Alert sx={{ 'display': message ? 'flex' : 'none' }}>
                {message}
              </Alert>
            </Box>
            {errors.non_field_errors ? (
              <Alert severity="error">
                <AlertTitle>{errors.non_field_errors}</AlertTitle>
                Go back to <Link href="/password-reset/">request page</Link>
              </Alert>
            ) : null}
          </Box>
        ) : (
          <Box
            component="form"
            onSubmit={handleSubmitRequest}
            noValidate
            sx={{ m: 1 }}
          >
            <TextField
              id="email"
              value={values.email}
              onChange={handleChange('email')}
              required
              fullWidth
              margin="normal"
              autoComplete="email"
              autoFocus
              label="Email Address"
              error={Boolean(errors.email)}
              helperText={errors.email}
            />
            <LoadingButton
              loading={fetching}
              disabled={Boolean(message)}
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Request password
            </LoadingButton>
            <Alert sx={{ 'display': message ? 'flex' : 'none' }}>
              {message}
            </Alert>
          </Box>
        )}
        <Grid item>
          <Link href="/login" variant="body2">
            Login
          </Link>
        </Grid>
      </Box>
    </Container>
  )
}

const mapStateToProps = (state: any) => {
  return {
    fetching: state.auth.fetching,
    errors: state.auth.errors,
    message: state.auth.message,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    requestPasswordReset: (email: string) => dispatch(AuthActions.requestPasswordReset(email)),
    passwordReset: (id: any, token: any, password: string) => dispatch(AuthActions.passwordReset(id, token, password)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PasswordResetPage);
