import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'
import {iSimpleUser} from "./authRedux";

/* ------------- Types and Action Creators ------------- */

export const STATUS_NEW = 1
export const STATUS_IN_PROGRESS = 2
export const STATUS_CONFIRMED = 3
export const STATUS_REJECTED = 4
export const STATUS_DEPRECATED = 5
// @todo use enum
export const STATUS_LIST = {
  1: 'New',
  2: 'In progress',
  3: 'Confirmed',
  4: 'Rejected',
  5: 'Deprecated',
}

export interface iDispute {
  readonly id?: number
  readonly evaluation_request_id?: number
  status: number
  vote: number
  candidate_id: number
  user?: iSimpleUser
  manager?: iSimpleUser
  comment: string

  is_matched_by_system?: boolean|null
  is_matched_by_user?: boolean|null

  created_at?: string
  updated_at?: string
}

const { Types, Creators } = createActions({
  updateItem: ['item'],
  updateCount: ['count'],
  updateList: ['list'],
  setComment: ['id', 'message'],
  updateFetching: ['fetching'],
  updateStatistic: ['statistic'],
  resetState: null,

  select: null,
  get_statistic: null,
  createDispute: ['data'],
  updateDispute: ['id', 'data'],
  listDispute: ['page', 'pageSize', 'sort', 'filter'],
})

export const DisputeTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export interface iStatistic {
  'count': [
    {
      'status': number,
      'total': number,
    }
  ]
}

type DisputeState = {
  item: iDispute,
  count: number,
  list: iDispute[],
  fetching: boolean,
  statistic: iStatistic,
}

export const INITIAL_STATE = Immutable({
  item: false,
  count: 0,
  list: [],
  fetching: false,
  statistic: {},
})

/* ------------- Reducers ------------- */

export const updateItem = (state: any, { item }: DisputeState) =>
  state.merge({ item })

export const updateCount = (state: any, { count }: any) =>
  state.merge({ count })

export const updateList = (state: any, { list }: DisputeState) =>
  state.merge({ list })

export const updateFetching = (state: any, { fetching }: DisputeState) =>
  state.merge({ fetching })

export const updateStatistic = (state: any, { statistic }: any) =>
  state.merge({ statistic })

export const resetState = () =>
  INITIAL_STATE

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.UPDATE_ITEM]: updateItem,
  [Types.UPDATE_COUNT]: updateCount,
  [Types.UPDATE_LIST]: updateList,
  [Types.UPDATE_FETCHING]: updateFetching,
  [Types.UPDATE_STATISTIC]: updateStatistic,
  [Types.RESET_STATE]: resetState,
})
