import React from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Alert, Avatar, Box, CircularProgress, Container, Grid, Link, Typography } from '@mui/material'
import { EmailRounded } from '@mui/icons-material'

import AuthActions from '../redux/authRedux'

type Props = {
  fetching: boolean,
  errors: any,
  message: string,
  confirmEmailAction: (id: string, token: string) => void,
}

type Params = {
  id: string,
  token: string,
}

function ConfirmEmailPage(props: Props) {
  const { fetching, errors, message, confirmEmailAction } = props
  const { id, token } = useParams<Params>()

  React.useEffect(() => {
    if (id && token) confirmEmailAction(id, token)
  }, [id, token, confirmEmailAction])

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <EmailRounded />
        </Avatar>
        <Typography component="h1" variant="h5">
          Email confirm
        </Typography>
        {fetching ? <CircularProgress /> : false}
        <Alert severity={message ? 'success' : 'error'}>
          {message ? message : Object.keys(errors).map(key => errors[key])}
        </Alert>
        <Box sx={{ m: 1 }}>
          <Grid container>
            <Grid item xs>
              <Link href="/login" variant="body2">
                Login
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  )
}

const mapStateToProps = (state: any) => {
  return {
    fetching: state.auth.fetching,
    errors: state.auth.errors,
    message: state.auth.message,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    confirmEmailAction: (id: string, token: string) => dispatch(AuthActions.confirmEmail(id, token)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmEmailPage);
