import React, {useEffect, useState} from 'react'
import { connect } from 'react-redux'
import { shallow } from 'zustand/shallow'

import {
  Avatar,
  Badge, Button,
  Dialog, DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  LinearProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText, TextField
} from '@mui/material'
import { Send as NewIcon }  from '@mui/icons-material'

import { iUser } from '../../../redux/authRedux'
import { iChat, UseMessageStore } from '../../../stores/UseMessageStore'
import { datetimeFormatter } from '../../../utils/formatters'
import AnnotatorChat from './AnnotatorChat';
import UserAutocomplete from '../../inputs/UserAutocomplete';

interface iManagerChat {
  user: iUser
}

interface iListOfChats extends iManagerChat {
  onSelect: (value: iChat) => void
}

const INITIAL_MESSAGE = {
  recipients: [],
  text: '',
}

function ListOfChats({ user, onSelect }: iListOfChats) {
  const { chats, loadChats, createChats, loading } = UseMessageStore((state) => ({
    chats: state.chats,
    loadChats: state.loadChats,
    createChats: state.createChats,
    loading: state.loading,
  }), shallow)
  const [createNew, showCreateModal] =  React.useState<boolean>(false)
  const [newMessage, setNewMessage] =  React.useState<any>(INITIAL_MESSAGE)

  const handleCreateMessage = async () => {
    createChats(newMessage.text, newMessage.recipients)
    setNewMessage(INITIAL_MESSAGE)
    showCreateModal(false)
  }

  useEffect(() => {
    loadChats()
    const intervalId = setInterval(() => {
      loadChats()
    }, 30 * 1000)

    return () => clearInterval(intervalId)
  }, [loadChats])

  return <>
    <Dialog
      fullWidth
      open={createNew}
      onClose={() => showCreateModal(false)}
    >
      <DialogTitle>Create new message</DialogTitle>
      <DialogContent style={{ paddingTop: 20 }}>
        <UserAutocomplete
          value={newMessage.recipients}
          onChange={(recipients: any) => setNewMessage({ ...newMessage, recipients })}
          inputLabel="Add recipient"
          inputHelperText="Leave blank to send to all"
        />
        <TextField
          autoFocus
          margin="dense"
          label="Text"
          fullWidth
          error={newMessage.text.length < 20}
          variant="outlined"
          value={newMessage.text}
          onChange={({ target: { value } }) => setNewMessage({ ...newMessage, text: value })}
          multiline
          rows={5}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => showCreateModal(false)}>Cancel</Button>
        <Button
          disabled={newMessage.text.length < 20}
          onClick={handleCreateMessage}
        >Send</Button>
      </DialogActions>
    </Dialog>
    <ListItem alignItems="flex-start" onClick={() => showCreateModal(true)}>
      <ListItemAvatar>
        <NewIcon />
      </ListItemAvatar>
      <ListItemText primary="Start new chats" />
    </ListItem>
    <List sx={{ width: 360, maxHeight: 500, overflow: 'auto', bgcolor: 'background.paper' }}>
      {chats.map((chat) => (
        <React.Fragment key={chat.sender.id}>
          <Divider variant="inset" component="li" />
          <ListItem alignItems="flex-start" onClick={() => onSelect(chat)}>
            <ListItemAvatar>
              <Badge badgeContent={chat.unread_count} color={chat.recipient_id === user.id ? 'error' : 'info'}>
                <Avatar alt={`${chat.sender.first_name} ${chat.sender.last_name}`} />
              </Badge>
            </ListItemAvatar>
            <ListItemText
              primary={`${chat.sender.first_name} ${chat.sender.last_name}`}
              secondary={datetimeFormatter(chat.last_message)}
            />
          </ListItem>
        </React.Fragment>
      ))}
      {loading && <LinearProgress />}
    </List>
  </>
}

function ManagerChat({ user }: iManagerChat) {
  const [chat, openChat] = useState<null | iChat>(null)

  return <>
    {chat
      ? <AnnotatorChat chatWithID={chat.sender.id} onClickBack={() => openChat(null)} />
      : <ListOfChats user={user} onSelect={openChat} />
    }
  </>
}

const mapStateToProps = (state: any) => {
  return {
    user: state.auth.user,
  }
}

export default connect(mapStateToProps)(ManagerChat)
