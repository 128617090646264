import React, { useEffect } from 'react'
import { shallow } from 'zustand/shallow'

import { Chip } from '@mui/material'
import {
  Close as CloseIcon,
  DoneAll as DoneAllIcon,
  Done as DoneIcon,
  Pending as PendingIcon,
  Timer as TimerIcon,
} from '@mui/icons-material'
import {
  DataGrid,
  GridColDef,
  GridColumnHeaderParams,
  GridToolbar,
  getGridDateOperators,
  getGridNumericOperators,
  getGridStringOperators,
} from '@mui/x-data-grid'

import { DASH, PAGE_SIZES }  from '../../utils/constants'
import { VotingStore } from '../../stores/VotingStore'
import { datetimeFormatter } from '../../utils/formatters'

import Page from '../../components/Layout/Page'


function VoteHistoryPage() {
  const votingStore = VotingStore((state) => state, shallow)

  useEffect(() => votingStore.loadVoteHistory(), [
    votingStore.page,
    votingStore.pageSize,
    votingStore.sort,
    JSON.stringify(votingStore.filter),
  ])

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      type: 'number',
      minWidth: 150,
      filterOperators: getGridNumericOperators().filter(
        (operator) => ['=', '>', '<'].includes(operator.value),
      ),
    },
    {
      field: 'user__email',
      headerName: 'User.email',
      renderHeader: (params: GridColumnHeaderParams) => (
        <div className="MuiDataGrid-columnHeaderTitle css-1jbbcbn-MuiDataGrid-columnHeaderTitle">
          User ID Name
        </div>
      ),
      type: 'string',
      minWidth: 250,
      filterOperators: getGridStringOperators().filter(
        (operator) => ['equals', 'contains'].includes(operator.value),
      ),
      valueGetter: (params: any) => `#${params.row.user.id} ${params.row.user.first_name} ${params.row.user.last_name}`
    },
    {
      field: 'status',
      headerName: 'Status',
      type: 'number',
      minWidth: 200,
      renderCell: ({ value, row }) => {
        if (value === 1) return (
          <Chip variant="outlined" color="default" icon={<TimerIcon />} label="New" />
        );
        if (value === 2) return (
          <Chip variant="outlined" color="info" icon={<PendingIcon />} label="In progress" />
        );
        if (value === 3 && row?.transaction) return (
          <Chip variant="outlined" color="success" icon={<DoneAllIcon />} label="Finished and paid" />
        );
        if (value === 3) return (
          <Chip variant="outlined" color="success" icon={<DoneIcon />} label="Finished" />
        );
        if (value === 5) return (
          <Chip variant="outlined" color="error" icon={<CloseIcon />} label="Skipped" />
        );
        return DASH
      },
    },
    {
      field: 'resolution',
      headerName: 'Resolution',
      minWidth: 200,
      filterOperators: getGridNumericOperators().filter(
        (operator) => ['=', '>', '<'].includes(operator.value),
      ),
      valueFormatter: ({ value }) => value ? `${value}%` : DASH,
    },
    {
      field: 'is_reference_vote',
      headerName: 'Is injection',
      type: 'boolean',
      minWidth: 120,
    },
    {
      field: 'transaction',
      headerName: 'Paid in transaction',
      minWidth: 200,
      valueFormatter: ({ value }) => value ? `#${value}` : DASH,
    },
    {
      field: 'created_at',
      headerName: 'Created At',
      type: 'date',
      minWidth: 250,
      filterOperators: getGridDateOperators().filter(
        (operator) => ['is', 'after', 'before'].includes(operator.value),
      ),
      renderCell: (params) => datetimeFormatter(params.value),
    },
    {
      field: 'ended_at',
      headerName: 'Finished At',
      type: 'date',
      minWidth: 250,
      filterOperators: getGridDateOperators().filter(
        (operator) => ['is', 'after', 'before'].includes(operator.value),
      ),
      renderCell: (params) => datetimeFormatter(params.value),
    },
    {
      field: 'dispute_ids',
      headerName: 'Dispute',
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        if (params.value?.length) {
          return params.value.map((id: any) => (
            <a href={`/disputes/${params.row.id}/`} target='_blank'>#{id}</a>
          ))
        }

        return (
          <a href={`/disputes/${params.row.id}/`} target='_blank'>open new</a>
        )
      },
    },
  ]

  const renderToolbar = () => (
    <GridToolbar
      csvOptions={{
        fileName: 'votes-history',
        delimiter: ';',
        utf8WithBom: true,
      }}
    />
  )

  return (
    <Page
      className="VoteHistoryPage"
      title="Vote History"
    >
      <DataGrid
        rows={votingStore.list}
        columns={columns}
        autoHeight={true}
        loading={votingStore.loading}
        components={{
          Toolbar: renderToolbar,
        }}
        // sorting
        sortingMode="server"
        sortModel={votingStore.sort}
        onSortModelChange={votingStore.setSort}
        // filtering
        filterMode="server"
        filterModel={votingStore.filter}
        onFilterModelChange={votingStore.setFilter}
        // pagination
        rowsPerPageOptions={PAGE_SIZES}
        rowCount={votingStore.count}
        page={votingStore.page}
        pageSize={votingStore.pageSize}
        paginationMode="server"
        onPageChange={votingStore.setPage}
        onPageSizeChange={votingStore.setPageSize}
      />
    </Page>
  )
}

export default VoteHistoryPage
