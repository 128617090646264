import React, { useState } from 'react'

import { iSimpleUser } from '../../redux/authRedux'
import { useDebouncedEffect } from '../../utils/useDebouncedEffect'
import API from '../../services/api'
import { buildUrls } from '../../utils/functions'

import {
  TextField,
  Autocomplete,
  Grid,
  Typography,
} from '@mui/material'

interface iProps {
  value: iSimpleUser[]
  onChange: (value: iSimpleUser[]) => void
  inputLabel?: string
  inputHelperText?: string
}

const UserAutocomplete = (props: iProps) => {
  const [inputValue, setInputValue] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [options, setOptions] = useState<readonly iSimpleUser[]>([])

  useDebouncedEffect(() => {
    const filter = {
      items: [
        {
          columnField: 'search',
          operatorValue: '=',
          value: inputValue,
        },
      ]
    }

    API.get(buildUrls('users/', {
      pageSize: 25,
      filter: inputValue && filter,
    })).then((response) => {
      if (response.ok) {
        setOptions(response.data.results)
      } else {
        console.error("Wrong server response", response)
        setErrorMessage("Something went wrong")
      }
    }).catch((e) => {
      console.error("Error on load users list", e)
      const message = (e as Error).message
      setErrorMessage(message)
    })
  }, [inputValue], 800)

  return (
    <Autocomplete
      multiple
      limitTags={10}
      fullWidth
      getOptionLabel={(option: iSimpleUser) =>
        typeof option === 'string' ? option : `${option.first_name} ${option.last_name}`
      }
      filterOptions={(x: any) => x}
      filterSelectedOptions
      options={options}
      autoComplete
      includeInputInList
      onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
      renderInput={(params) => (
        <TextField
          {...params}
          label={props.inputLabel}
          error={!!errorMessage}
          helperText={errorMessage || props.inputHelperText}
          fullWidth
        />
      )}
      renderOption={(props, option) => {
        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item xs>
                <Typography variant="body1" color="text.main">
                  {`${option.first_name} ${option.last_name}`}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {option.email}
                </Typography>
              </Grid>
            </Grid>
          </li>
        )
      }}
      value={props.value}
      onChange={(event, newValue) => props.onChange(newValue)}
    />
  )
}

export default UserAutocomplete
